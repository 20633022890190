import styled from 'styled-components';

const Container = styled.div`
  overflow-x: hidden;
  @media (min-width: 576px) {
    ${({ top }) => {
      if (top) {
        return `
            .styled-row {
              justify-content: flex-end;
            }
            .copy {
              padding-left: 0;
              position: absolute;
              top: ${top}px;
              left: 0;
            }
          `;
      }
    }}
  }
`;

export { Container };
