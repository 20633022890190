import React, { useEffect, useState } from 'react';
import useDomElement from 'hooks/useDomElement';
import transformTemplateData from 'utils/transformTemplateData';
import ContentfulSection from 'components/Contentful/ContentfulSection';

import { Container } from './styles';

const ExtensionLadder = (props) => {
  const [top, setTop] = useState(null);
  const copyEle = useDomElement('.conversion-panel .copy');

  useEffect(() => {
    if (copyEle) {
      const distanceFromTop = copyEle.offsetTop;
      setTop(distanceFromTop);
    }
  }, [copyEle]);

  const cleanData = (({ entries, ...rest }) => rest)(props);
  const data = transformTemplateData(cleanData);

  return (
    <Container className="conversion-panel" top={top}>
      <ContentfulSection {...data} />
    </Container>
  );
};

export default ExtensionLadder;
